import React, { useState, useRef, useMemo } from 'react'
import useCustomTranslation from 'src/utils/translation'
import { Button, Row, Typography } from 'antd'
import parse from 'html-react-parser'

import PoweredBy from 'src/components/PoweredBy'
import CardCart from 'src/components/card/cardCart'
import { formattedPrice } from 'src/utils/price'
import { trackEvent } from 'src/utils/tracking'
import { addGarmentSizesToCart } from 'src/utils/garment'
import useShowRecommendationsModal from 'src/utils/showRecommendationsModal'
import { useAppSelector } from 'src/store'

const { Title, Paragraph } = Typography

const CartPage = React.forwardRef<HTMLDivElement>((_, ref) => {
    const { t } = useCustomTranslation()
    const { showModal } = useShowRecommendationsModal()
    const look = useAppSelector((state) => state.look?.request)
    const company = useAppSelector((state) => state.profile?.company)
    const priceFloat = useAppSelector((state) => state.profile?.company?.price_float)
    const [sizes, setSizes] = useState<{ [key: string]: string | Models.MultiSize[] }>({})
    const firstCardFocusFunc = useRef(null)
    const [addingToCart, setAddingToCart] = useState(false)

    const garmentImgRatio = 100 / (company?.garment_image_ratio || 0.66)

    const garmentTypes = useMemo(() => {
        return company.garment_types
            ? company.garment_types
            : ['TOP', 'BOTTOM', 'DRESS', 'OUTERWEAR']
    }, [company])

    // ---- Var used to know which cardCart should use the focus function if we want to buy the look ----
    const firstNoSizeGarmentType = useMemo(() => {
        if (!look) {
            return 'TOP'
        }

        // ---- We get the garmentTypes that are present on the look ----
        const lookGarmentTypes = garmentTypes.filter((type) => look[type.toLowerCase()])
        let result = lookGarmentTypes[0]

        // ---- We get the keys (garmentType) of the already selected sizes ----
        const sizesKeys = Object.keys(sizes)

        if (sizesKeys.length > 0) {
            // ---- Get the lookGarmentTypes array WITHOUT the keys present in the sizesKeys array ----
            const filteredGarmentTypes = lookGarmentTypes.filter(
                (type) => !sizesKeys.includes(type)
            )
            result = filteredGarmentTypes[0]
        }

        return result
    }, [sizes, garmentTypes, look])

    let nbSelected = 0
    let totalPrice = 0
    let currency = null
    for (const type of garmentTypes) {
        const typeLower = type.toLowerCase()
        if (look && sizes[type] && look[typeLower]) {
            nbSelected += 1
            const foundSize = (look[typeLower].product_sizes as Models.OldGarmentSize[]).find(
                (productSize) => productSize.value === sizes[type]
            )
            if (foundSize && foundSize.price) {
                totalPrice += foundSize.price
            } else {
                totalPrice += look[typeLower].product_price
            }
        }
        if (look && look[typeLower] && currency === null) {
            currency = look[typeLower].product_currency
        }
    }

    const handleAddToCart = () => {
        const params = []
        const garmentIds = []
        for (const type of garmentTypes) {
            const typeLower = type.toLowerCase()
            if (sizes[type]) {
                trackEvent(
                    'Item Added to cart',
                    [look[typeLower], { item_size_selected: sizes[type] }],
                    'Outfit Detail'
                )
                params.push({ garment: look[typeLower], currentSize: sizes[type] })
                garmentIds.push(look[typeLower].garment_id)
            }
        }
        if (params.length === garmentTypes.filter((type) => look[type.toLowerCase()]).length) {
            trackEvent(
                'Outfit Added to cart',
                [
                    look,
                    {
                        outfit_nb_items_selected: nbSelected,
                        outfit_total_price_selected: totalPrice,
                    },
                ],
                'Outfit Detail'
            )
            setAddingToCart(true)
            addGarmentSizesToCart(params, (success) => {
                if (!success) {
                    console.error(`Timeout when adding to cart`)
                }
                setAddingToCart(false)
                showModal(garmentIds, look, 'Outfit Detail')

                // ---- Reset sizes after adding the whole look to cart ----
                setSizes({})
            })
        } else if (firstCardFocusFunc?.current) {
            firstCardFocusFunc?.current()
        }
    }

    if (!look) {
        return (
            <div className='cart cart--container'>
                <div className='cart--empty'>{t('cart.no_products')}</div>
            </div>
        )
    }

    const handleSizeChange = (type: string, value: string | Models.MultiSize[]) => {
        // ---- Reset the ref if we select a garment type that's the same as the firstNoSizeGarmentType ----
        if (type === firstNoSizeGarmentType) {
            firstCardFocusFunc.current = null
        }

        setSizes({ ...sizes, [type]: value })
    }

    return (
        <div
            ref={ref}
            className={`cart cart--container${
                company.enable_buy_look ? ' cart--container-with-buy-look' : ''
            }`}
            id='cartContainerId'
        >
            {company.enable_buy_look && (
                <div className='cart--buy-look-container button--desktop'>
                    <Button
                        type='primary'
                        size='large'
                        className='button--default button--buy-look'
                        loading={addingToCart}
                        onClick={handleAddToCart}
                    >
                        {t('cart.buy_look')}
                    </Button>
                    <div className='cart cart--text'>
                        <Paragraph
                            ellipsis={{
                                rows: 1,
                            }}
                            className='text text--small'
                            style={{ marginBottom: 0 }}
                        >{`${
                            nbSelected > 1
                                ? t('cart.item_selected')
                                : t('cart.item_selected_singular')
                        }: ${nbSelected}`}</Paragraph>
                        <Title
                            ellipsis={{
                                rows: 1,
                            }}
                            className='title title--h2'
                        >
                            {formattedPrice(totalPrice, currency, priceFloat)}
                        </Title>
                    </div>
                </div>
            )}
            <div>
                {look.cart_add_card_html && <Row>{parse(look.cart_add_card_html)}</Row>}
                <Row className='cart'>
                    {garmentTypes
                        .filter((type) => look[type.toLowerCase()])
                        .map((type) => {
                            const garment = look[type.toLowerCase()]
                            if (garment && garment.product_name) {
                                return (
                                    <CardCart
                                        ratio={garmentImgRatio}
                                        key={garment.garment_id}
                                        garment={garment}
                                        value={sizes[type] || null}
                                        onChange={(value) => handleSizeChange(type, value)}
                                        cardFocusFunc={
                                            firstNoSizeGarmentType === type
                                                ? firstCardFocusFunc
                                                : null
                                        }
                                        showRecommendation={
                                            window.innerWidth / window.innerHeight < 1
                                                ? false
                                                : true
                                        }
                                    />
                                )
                            }
                        })}
                </Row>
            </div>
            <PoweredBy />
        </div>
    )
})

CartPage.displayName = 'CartPage'

export default CartPage
